<template>
  <div>
    <p class="text-lg">Customer Data</p>
    <hr />

    <div class="my-2" v-if="form.so_types == 'without_so_reference'">
      <label class="vs-input--label">Select Territory</label>
      <SelectTerritory
        @data="setSelectTerritory"
        ref="selectTerritory"
        :isDisable="form.isEdit || form.so_types == 'with_so_reference'"
        :params="this.terr_params"
      />
    </div>

    <div class="my-2">
      <label class="vs-input--label">Pickup From / Ship To</label>
      <SelectCustomerAddress
        @data="setSelectCustomerAddress"
        ref="selectCustomerAddress"
        :isDisabled="
          form.so_types == 'with_so_reference' ||
          form.isDetail == 1 ||
          form.isEdit
        "
        :formDatas="form"
      />
    </div>

    <div class="my-2">
      <label class="vs-input--label">Customer</label>
      <SelectCustomer
        @data="setSelectCustomer"
        ref="selectCustomer"
        :isWithoutSoRef="form.so_types == 'without_so_reference'"
        :isDisabled="
          form.so_types == 'with_so_reference' ||
          form.isDetail == 1 ||
          form.isEdit
        "
      />
    </div>

    <div class="my-2">
      <label class="vs-input--label">Customer Name</label>
      <vs-input
        readonly
        class="w-full"
        v-model="form.customer_data.customer_name"
        :disabled="form.isDetail == 1"
      />
    </div>

    <div class="my-2">
      <label class="vs-input--label">Customer Sold To</label>
      <SelectCustomerBillTo
        @data="setSelectCustomerBillTo"
        ref="selectCustomerBillTo"
        :isDisabled="
          form.so_types == 'with_so_reference' ||
          form.isDetail == 1 ||
          form.isEdit
        "
      />
    </div>

    <!-- MIX2-10274 field salesman on SR without ref -->
    <div class="my-2" v-if="form.so_types == 'without_so_reference'">
      <label class="vs-input--label">Select Salesman</label>
      <multiselect
        class="selectExample"
        v-model="salesPersonal.selected"
        :options="salesPersonal.option"
        :multiple="false"
        :allow-empty="true"
        :max-height="120"
        :limit="3"
        placeholder="Type to search"
        :loading="isLoadingSales"
        track-by="ID"
        @search-change="handlerSearchSales"
        @input="onselectedSalesman"
        :custom-label="customLableSalesman"
        :disabled="(form.isDetail == 1 || form.so_types == 'with_so_reference') && !form.isEdit"
      ></multiselect>
    </div>
  </div>
</template>

<script>
import SelectCustomer from "@/components/master/SelectCustomer.vue";
import SelectCustomerAddress from "@/components/master/SelectCustomerAddress.vue";
import SelectCustomerBillTo from "@/components/master/SelectCustomerBillTo.vue";
import SelectTerritory from "@/components/master/Territory.vue";

export default {
  components: {
    SelectCustomer,
    SelectCustomerAddress,
    SelectCustomerBillTo,
    SelectTerritory,
  },
  props: {
    form: {
      type: Object,
      default: () => {},
    },
    isDisabledFormInvoice: {
      type: Boolean,
      default: false,
    },
    terr_params: null,
  },
  mounted() {
    if (this.form.customer_data.customer_id) {
      this.$refs.selectCustomer.setIds(this.form.customer_data.customer_id);
    }
    if (this.form.customer_data.customer_code) {
      this.$refs.selectCustomer.setCodes(this.form.customer_data.customer_code);
    }
    if (this.form.customer_data.customer_bill_to_id) {
      this.$refs.selectCustomerBillTo.setByID(
        this.form.customer_data.customer_bill_to_id
      );
    }
    if (this.form.so_types == "with_so_reference") {
      this.$refs.selectCustomer.setIsDisabled(true);
    }

    if (this.form.isDetail == 1) {
      this.$refs.selectCustomer.setDisabled(true);
    }
    if (this.form.isDetail == 1 || this.form.isEdit == 1) {
      this.$refs.selectCustomerAddress.setDisabled(true);
      this.$refs.selectCustomerBillTo.setDisabled(true);
    }
  },
  methods: {
    async setSelectTerritory(data) {
      // set customer by territory
      this.form.territory_id = data.id;
      this.$refs.selectCustomer.setParams({
        territory_id: [data.id],
      });
      await this.$refs.selectCustomer.getData();
      await this.getOptionSalesID(false);
    },
    async setSelectCustomer(data) {
      if (this.form.so_types == "with_so_reference") {
        await this.$refs.selectCustomer.toggleDisabled();
      }

      const name = data.name;
      let customer_id = 0;
      if (data.ID != null) {
        this.form.customer_data.customer_id = data.ID;
        customer_id = data.ID;
      } else if (data.id != null) {
        this.form.customer_data.customer_id = data.id;
        customer_id = data.id;
      }
      this.form.customer_data.selected_customer_id = customer_id;
      this.form.customer_data.customer_name = name;
      this.form.customer_data.selected_customer = data;
      this.form.customer_data.pricing_group_id = data.pricing_group_id;
      this.form.customer_data.customer_group_id_1 = data.customer_group_id1;
      this.form.customer_data.customer_group_id_2 = data.customer_group_id2;
      this.form.customer_data.customer_group_id_3 = data.customer_group_id3;

      let shipTo_id = null;
      if (this.form.customer_return_data.customer_ship_to != 0) {
        shipTo_id = this.form.customer_return_data.customer_ship_to;
      }

      if (this.form.customer_invoice_data.sales_order_data != null) {
        shipTo_id = this.form.customer_invoice_data.sales_order_data.ShipmentToAddressID != 0 ? this.form.customer_invoice_data.sales_order_data.ShipmentToAddressID : null;
      }

      let territory_id = null;
      if (this.form.territory_id != 0) {
        territory_id = this.form.territory_id;
      }
      console.log({
        id: shipTo_id,
        territory_id: territory_id,
        customer_id: customer_id,
      });
      // set customer address -- dimatikan karena bikin infinite tsukuyomi, eh infitine loops
      await this.$refs.selectCustomerAddress.getDataShipTo({
        params: {
          id: shipTo_id,
          territory_id: territory_id,
          customer_id: customer_id,
        },
      });
      // set customer bill to
      // if (this.form.customer_data.customer_bill_to_id) {
      await this.$refs.selectCustomerBillTo.setFilters({
        customer_id: customer_id,
      });
      await this.$refs.selectCustomerBillTo.getData();
    },
    async setSelectCustomerAddress(data) {
      console.log("this.form", this.form)
      console.log("data", data)
      this.form.customer_data.pickup_from_ship_to = data;
      this.form.customer_return_data.customer_ship_to = data.ID
      this.form.sales_personal_id = data.sales_personal_id != 0 ? data.sales_personal_id : this.form.sales_personal_id;
      await this.getOptionSalesID(true);
      if (this.form.customer_data.selected_customer_id == null) {
        this.$refs.selectCustomer.setIds(data.customer_id);
      }
      if (this.form.customer_data.selected_customer_id == null) {
        this.$refs.selectTerritory.getData({params : {id:data.territory_id}});
      }
    },
    async setSelectCustomerBillTo(data) {
      this.form.customer_data.customer_sold_to = data;
    },
    async getOptionSalesID(is_default) {
      this.$vs.loading();
      try {
        let superadmin
        this.$userLogin.roles.filter(function(item) {
          if(item.Name == "superadmin"){
            superadmin = true;
          }else{
            superadmin = false;
          }
        });
        const resp = await this.$http.get("/api/v1/sales-order/personals",{
          params: {
            search: this.searchSales,
            territory_id: this.form.territory_id ? this.form.territory_id : null,
            sales_personal_id: this.form.sales_personal_id ? this.form.sales_personal_id : null,
          }
        });
        console.log("resp",resp);
        if (resp.code == 200) {
          this.salesPersonal.option = []
          this.salesPersonal.all_options = []
          let data = resp.data.salesman
          if (this.salesPersonal.selected != null) {
            this.salesPersonal.option.push(this.salesPersonal.selected)
            this.salesPersonal.all_options.push(this.salesPersonal.selected)
          }
          for(var idx = 0;idx<data.length;idx++){
            this.salesPersonal.option.push(data[idx])
            this.salesPersonal.all_options.push(data[idx])
          }
          if (!superadmin) {
            // set default Sales ID jika bukan superadmin
            this.salesPersonal.selected = this.salesPersonal.option.filter((v) => {
              return v.ID == this.$userLogin.id;
            });
          }
          if (is_default && this.form.customer_data.pickup_from_ship_to.sales_personal_id != 0 && data.length == 1) {
            this.salesPersonal.selected = resp.data.salesman[0];
          }
          if (is_default && this.form.customer_data.pickup_from_ship_to.sales_personal_id != 0 && data.length > 1) {
            this.salesPersonal.selected = this.salesPersonal.option.filter((v) => {
              return v.ID == this.form.customer_data.pickup_from_ship_to.sales_personal_id;
            });
          }
          if (this.form.sales_personal_id != 0 && data.length >= 1) {
            this.salesPersonal.selected = this.salesPersonal.option.filter((v) => {
              return v.ID == this.form.sales_personal_id;
            });
            this.salesPersonal.selected = this.salesPersonal.selected[0];
          }
          console.log("this.form.customer_data.pickup_from_ship_to.sales_personal_id", this.form.customer_data.pickup_from_ship_to.sales_personal_id)
          console.log("this.form", this.form)
          console.log("this.salesPersonal", this.salesPersonal)
        } else {
          this.$vs.notify({
            title: "Error",
            text: "Error while fetching customers",
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
          });
        }
      } catch (error) {
        this.$vs.notify({
          title: "Error",
          text: "Error while fetching salesman",
          color: "danger",
          iconPack: "feather",
          icon: "icon-alert-circle",
        });
        console.log(error);
      }
      this.$vs.loading.close();
    },
    async handlerSearchSales(search){
      this.searchSales = search
      this.form.sales_personal_id = null
      if(search != ""){
        await this.getOptionSalesID(false);
      }
    },
    customLableSalesman({ Name, CodeExternal }){
      return (CodeExternal != "" && CodeExternal) ? `[ ${CodeExternal} ] ${Name}` : `[ - ] ${Name}`;
    },
    onselectedSalesman(select){
      console.log("onselectedSalesman", select)
      this.form.sales_personal_id = select.ID
      console.log("this.form.sales_personal_id", this.form.sales_personal_id)
      console.log("this.form", this.form)
    },
  },
  watch: {
    "form.customer_data.customer_id": {
      handler: function (val) {
        this.$refs.selectCustomer.setIds(val);
      },
    },
    "form.customer_data.customer_code": {
      handler: function (val) {
        if (val != null) {
          this.$refs.selectCustomer.setCodes(val);
        }
      },
    },
    "form.customer_data.customer_bill_to_id": {
      handler: function (val) {
        if (val != null) {
          this.$refs.selectCustomerBillTo.setByID(val);
        }
      },
    },
    "form.so_types": {
      handler: function (val) {
        console.log("this.form", this.form)
        if (val == "with_so_reference") {
          this.$refs.selectCustomer.setIsDisabled(true);
          if (this.form.customer_invoice_data == null || this.form.customer_invoice_data.customer_invoice_id == null){
            this.form.customer_data.customer_name = null;
          }
        } else {
          this.$refs.selectCustomer.setIsDisabled(false);
          this.form.customer_data.customer_name = null;
        }
      },
    },
    "form.isDetail": {
      handler: function (val) {
        if (val == 1) {
          this.$refs.selectCustomer.setDisabled(true);
          this.$refs.selectCustomerAddress.setDisabled(true);
          this.$refs.selectCustomerBillTo.setDisabled(true);
        }
      },
    },
  },
  data(){
    return{
      salesPersonal: {
        selected: null,
        option: [],
        all_options: [],
      },
      isLoadingSales:false,
      searchSales:"",
    }
  },
};
</script>
